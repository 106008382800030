<template>
  <b-card-code>
    <b-row>
      <b-col
        md="6"
        xl="6"
        class="mb-1"
      >
        <div class="d-flex justify-content-begin">
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="gotoCheckout"
            >
              Merge ({{ selectedQty }})
            </b-button>
            <b-button
              variant="success"
              style="margin-left: 10px;"
              @click="createProject($event)"
            >
              Create new project
            </b-button>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
    <div class="custom-search">
      <!-- advance search input -->
      <b-row>
        <b-col md="4">
          <b-form-group>
            <label>Keyword:</label>
            <b-form-input
              placeholder="Search for project name, owner"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Customer:</label>
            <!-- <b-form-input
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            /> -->
            <v-select
              v-model="selCustomer"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="option"
              label="text"
              @input="advanceSearchVselect"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>Hashtag:</label>
            <!-- <b-form-input
              placeholder="Search"
              type="text"
              class="d-inline-block"
              @input="advanceSearch"
            /> -->
            <v-select
              v-model="selHashtags"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="hashtagName"
              multiple
              :options="optionMultiple"
              @input="advanceSearchVselectMultiple"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <!-- table -->
    <vue-good-table
      ref="myTable"
      :columns="columns"
      :rows="rows"
      :line-numbers="true"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
        searchFn: myFunc,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      @on-selected-rows-change="selectionChanged"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'projectName'"
          class="text-nowrap"
        >
          <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">
            <router-link :to="{ name: 'createProject', params: {projectId: props.row.projectId } }">

              {{ props.row.projectName }}</router-link> <br>{{ props.row.customerName }}
          </span> -->

          <div class="d-flex">
            <!-- <b-avatar :text="props.row.projectName.slice(0,2)" /> -->
            <b-avatar
              :src="$webApi + '/coverPicturePath/' + props.row.coverPicture"
            />
            <div class="ml-25">
              <b-card-text class="font-weight-bold mb-0">
                {{ props.row.projectName }}
              </b-card-text>

              <small>{{ props.row.customerName }}</small>
            </div>
          </div>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'hashtagsName'">
          <b-badge
            v-for="(name, index) in props.row.hashtagsName.split(' ')"
            :key="index"
            style="margin-right:5px"
            variant="light-primary"
          >
            {{ name }}
          </b-badge>

          <!-- <label
            v-for="(name, index) in props.row.hashtagsName.split(' ')"
            :key="index"
          >
            {{ name }}
          </label> -->
        </span>

        <span v-else-if="props.column.field === 'lastUpdate'">
          {{ props.row.lastUpdate | dateOrNull }}
          <br>

        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <b-badge variant="light-secondary">
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </b-badge>
              </template>
              <b-dropdown-item @click="gotoProject(props.row.projectId)">
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span>View</span>
              </b-dropdown-item>
              <b-dropdown-item @click="gotoEditProject(props.row.projectId)">
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <!-- <b-dropdown-item>
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100']"
              class="mx-1"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <br>

    <template #code>
      {{ codeBasicGoodTable }}
    </template>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeBasicGoodTable } from './code'

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,

    BCardText,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      keyword: '',
      hasItems: false,
      selectedQty: 0,
      selCustomer: {},
      selHashtags: null,
      option: [],
      optionMultiple: [],
      backSelectItems: [],
      pageLength: 10,
      dir: false,
      codeBasicGoodTable,
      columns: [
        {
          label: '#',
          field: 'projectId',
          hidden: true,
        },
        {
          label: 'Project',
          field: 'projectName',
        },
        {
          label: 'CustomerName',
          field: 'customerName',
          hidden: true,
        },
        {
          label: 'Team Owner',
          field: 'teamOwnerName',
        },
        {
          label: 'Hashtag',
          field: 'hashtagsName',
        },
        {
          label: 'Last Update',
          field: 'lastUpdate',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      status: [
        {
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    if (this.getItems() != null && this.getItems().length > 0) {
      this.hasItems = true
      this.selectedQty = this.getItems().length
    }

    useJwt.getHashtags().then(res => {
      this.optionMultiple = res.data.list
    })
    useJwt
      .getCustomers()
      .then(res => {
        this.option = res.data.list
      })
      .catch(error => {
        this.$swal({
          icon: 'error',
          title: 'Oops...',
          html: `Something went wrong.<br> (${error.response.data.message})`,
        })
      })

      // eslint-disable-next-line prefer-destructuring
      .then((this.selCustomer = this.option[0]))
  },
  mounted() {
    this.search('', '', '')
  },
  methods: {
    advanceSearchVselectMultiple(val) {
      // console.log(val.length)
      if (val.length === 0) {
        this.searchTerm = ''
      } else {
        const text = val.map(elem => `#${elem.hashtagName}`).join(' ')
        this.searchTerm = text
        this.searchTerm = this.searchTerm.trim()
      }
    },

    advanceSearchVselect(val) {
      if (val == null) {
        this.searchTerm = ''
      } else {
        this.searchTerm = val.value
        console.log(this.searchTerm)
      }
    },

    advanceSearch(val) {
      this.searchTerm = val
    },
    search(keywords, customer, hashtags) {
      useJwt
        .SearchProject(keywords, customer, hashtags)
        .then(res => {
          this.rows = res.data.list
        })
        .then(() => {})
        .catch(error => console.log(error))
    },
    getItems() {
      const strItems = localStorage.getItem('items')
      // console.log(strItems)
      if (strItems != null && strItems.length !== 0) return JSON.parse(strItems)
      return null
    },
    addToCart() {
      const items = this.$refs.myTable.selectedRows.map(
        element => element.projectId,
      )
      if (items.length === 0) {
        return
      }
      if (this.getItems() !== null) {
        const existingItems = this.getItems()
        const newlyItems = items.filter(e => !existingItems.includes(e))
        existingItems.push(...newlyItems)
        localStorage.setItem('items', JSON.stringify(existingItems))
      } else {
        localStorage.setItem('items', JSON.stringify(items))
      }

      // console.log(localStorage.getItem('items'))
      this.$refs.cart.setItems(items)
    },
    gotoCheckout() {
      if (this.getItems() == null || this.getItems().length === 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Warning',
            icon: 'CoffeeIcon',
            variant: 'warning',
            text: 'There are 0 project to merge',
          },
        })
        return
      }
      this.$router.push({ name: 'checkout' })
    },
    selectionChanged(params) {
      let projectIds = []
      if (params.selectedRows.length > 0) {
        projectIds = params.selectedRows.map(element => element.projectId)
      }
      if (this.hasItems === false) {
        this.setItems(projectIds)
        console.log(this.getItems())
      } else if (params.selectedRows.length > 0) {
        const existingItems = this.getItems()
        const newlyItems = projectIds.filter(e => !existingItems.includes(e))
        existingItems.push(...newlyItems)
        this.setItems(existingItems)
        const prev = this.backSelectItems
        this.backSelectItems = projectIds
        console.log(existingItems)
        if (prev > projectIds) {
          let origin = this.getItems()
          const deleting = prev.filter(e => !projectIds.includes(e))
          console.log(deleting)
          origin = origin.filter(e => !deleting.includes(e))
          console.log(origin)
          this.setItems(origin)
        }
      } else if (this.hasItems === true) {
        const existingItems = this.getItems()
        const prev = this.backSelectItems
        console.log(prev)
        const deleted = existingItems.filter(e => !prev.includes(e))
        this.setItems(deleted)
        console.log(deleted)
        console.log('HI')
      }
    },
    setItems(items) {
      localStorage.setItem('items', JSON.stringify(items))
      this.selectedQty = items.length
    },
    createProject() {
      this.$router.push({ name: 'createProject', params: { projectId: 0 } })
    },
    gotoProject(projectID) {
      // console.log(projectID)
      this.$router.push({ name: 'detail', params: { projectId: projectID } })
    },
    gotoEditProject(projectID) {
      this.$router.push({
        name: 'editProject',
        params: { projectId: projectID },
      })
    },
    // eslint-disable-next-line
    myFunc(row, col, cellValue, searchTerm) {
      const hashtags = searchTerm.split(' ')
      const cellVal = cellValue.toString().toLowerCase()

      if (hashtags.length === 0) {
        return cellVal.includes(searchTerm.toLowerCase())
      }

      for (let index = 0; index < hashtags.length; index += 1) {
        if (cellVal.includes(hashtags[index].toLowerCase())) {
          return true
        }
      }
      return false
    },
  },
}
</script>
<style scoped>
.b-avatar {
  width: 3rem;
  height: 3rem;
}
</style>
