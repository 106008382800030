<template>
  <b-row class="match-height">
    <!-- <b-col cols="12">
      <criteria @my-event="test" />
    </b-col> -->
    <b-col cols="12">
      <project-table ref="gtb" />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import ProjectTable from './ProjectTable.vue'

export default {
  components: {
    BRow,
    BCol,

    ProjectTable,
  },
  data() {
    return {
    }
  },
  methods: {
    test(keyword, customer, hashtags) {
      this.$refs.gtb.search(keyword, customer, hashtags)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
